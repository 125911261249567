export const resumeData = {
  pictureAndInfoBox: {
    name: "Jason Wortley",
    title: "Web Developer",
    currentResidence: "Seattle, Washington",
    contactInfo: "jason@jasonwortley.com",
    externalURLs: [
      "https://www.facebook.com/JasonWortleyDynatos",
      "https://www.github.com/dynatos",
      "https://www.linkedin.com/in/jason-wortley-902627148/"
    ],
    externalURLImages: [
      "https://login.create.net/images/icons/user/facebook_30x30.png",
      "https://github.githubassets.com/images/modules/logos_page/GitHub-Mark.png",
      "https://cdn0.iconfinder.com/data/icons/social-flat-rounded-rects/512/linkedin-256.png"
    ]
  },

  dataBox: {
    aboutMe: `Welcome to my personal website! Here I host some of the projects that I've worked on over the course of
    learning web development. This site is a Node.js app utilizing many modern libraries/technologies such as
    Babel, Webpack, React, & Redux. You can check out the source code on my GitHub under 'personal-website'. It's
    also transpiled, bundled, and minified with Babel and Webpack (among other things). Hosting is trivialized thanks
    to Docker & Nginx. Let's Encrypt is also utilized for a free SSL certificate, which enables us to serve over HTTPS.
    Feel free to contact me with questions, projects, & employment opportunities!`,
    experience: [
      {
        company: "North Shore Geeks",
        position: "Computer Repair Technician",
        startDate: "2010",
        endDate: "2013"
      },
      {
        company: "Kauai Nut Roasters",
        position: "Owner & Kitchen Manager",
        startDate: "July 2012",
        endDate: "March 2017"
      },
      {
        company: "Kauai Nut Roasters",
        position: "Website Manager",
        startDate: "November 2016",
        endDate: "Present Day"
      },
      // {
      //   company: "test",
      //   position: "test",
      //   startDate: "test",
      //   endDate: "test"
      // }
    ],
    skills: ["HTML", "CSS", "JavaScript", "React", "Redux", "Node.js", "Git", "Docker", "MySQL"]
  }
};

// const specificEmploymentDates = {
//   company: "Kauai Nut Roasters",
//   position: [
//     {
//       title: "Nut Bagger",
//       startDate: "July 2012",
//       endDate: "September 2013"
//     },
//     {
//       title: "Nut Roaster",
//       startDate: "September 2013",
//       endDate: "July 2014"
//     },
//     {
//       title: "Head Roaster & Kitchen Manager",
//       startDate: "July 2014",
//       endDate: "March 2017"
//     },
//     {
//       title: "Owner",
//       startDate: "April 2016",
//       endDate: "March 2017"
//     }
//   ]
// };
